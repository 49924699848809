// Helpers for BEM Syntax
@mixin e($element) {
    & &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    $block: &;

    @at-root #{$block}#{$block}--#{$modifier} {
        @content;
    }
}
