.VueTables {
  position: relative;
  border-radius: 6px;

  table {
    border-spacing: 0;
    width: 100%;
    border-radius: 6px;
    overflow: visible !important;

    thead {
      th {
        font-weight: bolder !important;
        background-color: #F1F5F9 !important;
        padding: 8px 0;
        text-align: left;
        color: #fff;
      }

      th:first-of-type {
        border-top-left-radius: 6px;
      }

      th:last-of-type {
        border-top-right-radius: 6px;
      }
    }

    tbody {
      border: 1px solid #F1F5F9;
      background-color: #fff;

      tr {
        color: #606775;
        font-size: 14px;

        td {
          padding: 14px;
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
        }

        &.selected {
          background: #f1f8e9;
        }

        &.highlight {
          background: #cce5ff;
        }

        &.obtained {
          background: #f2f2f2;
        }

        &:last-of-type {
          border-radius: 6px;
        }
      }
    }

    tr:hover {
      cursor: pointer;
      background-color: #F1F5F9 !important;
    }
  }

  @include e('limit-field') {
    display: none !important;
  }

  @include e(no-results) {
    text-align: center;
  }

  @include e(heading) {
    font-size: 12px;
    line-height: 17px;
    color: #8b8b8b;
    padding-left: 12px;
    padding-right: 13px;
  }

  @include e(sortable) {
    user-select: none;

    .pull-right {
      float: none !important;
    }

    .sort-desc {
      content: url("/images/icons/sort-desc.svg");
    }

    .sort-asc {
      content: url("/images/icons/sort-asc.svg");
    }
  }

  @include e(sort-icon) {
    color: #8b8b8b;
  }

  @include e(primary-content) {
    font-size: 16px;
    color: #8b8b8b;
    padding-bottom: 3px;
  }

  @include e(primary-content-bold) {
    font-size: 16px;
    font-weight: bold;
    color: #8b8b8b;
    padding-bottom: 3px;
  }

  @include e(primary-content-icon) {
    height: 15px;
    width: 15px;
  }

  @include e(primary-content-icon-wrapper) {
    display: inline-block;

    svg {
      fill: #8b8b8b;
    }
  }

  @include e(secondary-content) {
    font-size: 12px;
    color: #d5d5d5;
  }

  @include e(search-field) {
    display: none;

    label {
      display: none;
    }
  }
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

.VueTables__child-row {
  .row {
    margin-bottom: 5px;
    line-height: 2em;
  }
}

.VueTables__child-row-td {
  display: flex;
  justify-content: space-around;
}

.table-no-border tbody {
  border: none !important;
}
