.VuePagination {
    text-align: right;
    margin: 0;
    font-weight: 400;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include e(count) {
        text-align: center;
        color: #505050;
        font-size: 14px;
    }

    @include e(pagination) {
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
        justify-content: center;
        -webkit-box-pack: end;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    @include e(pagination-item) {
        .page-link {
            position: relative;
            display: block;
            padding: 0.5rem 0.75rem;
            margin: 2px;
            line-height: 1.25;
            color: #666;
            font-weight: bold;
            font-size: 14px;
            background-color: #fff;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
        }
    }

    @include e(pagination-item) {
        .page-item {
            .page-link {
                transition: all 0.3s linear;
                outline: 0;
                border: 0;
                background-color: transparent;
                color: #666;
                font-weight: bold;
                font-size: 14px;
                border-radius: 5px;
            }
        }
    }

    @include e(pagination) {
        .page-item.active {
            .page-link {
                z-index: 1;
                transition: all 0.2s linear;
                background: #DB4538;
                color: #fff;
                border-radius: 5px;
            }
        }
    }

    @include e(pagination) {
        .page-item.disabled {
            .page-link {
                display: none;
            }
        }
    }
}
